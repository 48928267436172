.Faq__main-container{
    /* border: 3px solid blue; */
    padding: 0;
    margin: 0;
}
    .Faq__faqs-container{
        /* border: 10px solid green; */
        /* width: auto; */
        /* width: 2em; */
        width: 100%;
   
    }
        .Faq__faqs{
            /* border: 3px solid black; */
            /* display: flex;
            width: auto; */
            width: 30em
            
        }

        .Faq__faqs.columns {
             display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;  
           
          }
          
          .Faq__faqs.columns > * {
            width: auto; 
          }
          .Faq__main-container__main-title{
            color: #00030A;
            text-align: center;
            font-family: Montserrat;
            font-size: 2.5rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.05rem; 
            text-transform: uppercase;
            /* border: 2px solid red; */
            padding: .5em;
        }
        .Faq__customer-service-container{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 2.5em;
            margin-bottom: 1.5em;
        }



@media (max-width: 575.98px) {
    .Faq__main-container{
        /* border: 3px solid blue; */
        padding: 0;
        margin: 0;
    }
        .Faq__faqs-container{
            /* border: 10px solid rgb(0, 0, 0); */
            /* width: auto; */
            /* width: 2em; */
            width: 100%;
       
        }
            .Faq__faqs{
                /* border: 3px solid black; */
                /* display: flex;
                width: auto; */
                width: 30em
                
            }
    
            .Faq__faqs.columns {
                 display: grid;
                grid-template-columns: repeat(1, 1fr);
                gap: 20px;  
               
              }
              
              .Faq__faqs.columns > * {
                width: auto; 
              }


    .Faq__main-container__main-title{
        font-size: 2rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.05rem; 
        text-transform: uppercase;
        padding: .5em;
    }
    .Faq__faqs{
        display: flex !important;
        flex-direction: row !important;
        flex-wrap: wrap !important;
        justify-content: center !important;
         
     }
}
        
        
@media (min-width: 576px) and (max-width: 767.98px) {
    .Faq__main-container__main-title{
        font-size: 2rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.05rem; 
        text-transform: uppercase;
        padding: .5em;
    } 
    .Faq__faqs{
        display: flex !important;
        flex-direction: row !important;
        flex-wrap: wrap !important;
        justify-content: center !important;
         
     }
    
}


@media (min-width: 768px) and (max-width: 991.98px) {
    .Faq__main-container__main-title{
        font-size: 1.9rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.05rem; 
        text-transform: uppercase;
        padding: .5em;
    }
    
}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .Faq__main-container__main-title{
        font-size: 1.9rem;
    }

}

@media (min-width: 1200px) {
    .Faq__main-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 4em;

    }
}
          