.BrandImgSection__main-container{
    margin-bottom: 3em;
}
.BrandImgSection__icons-container{
    /* border: 3px solid blue; */
    display: flex;

}
    .BrandImgSection__icon-container{
        /* border: 3px solid green; */
    }
        .BrandImgSection__icon{
            /* border : 3px solid red; */
        }



.BrandImgSection__title-container h1{
    color: #00030A;
    text-align: center;
    font-family: Montserrat;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.05rem; 
    text-transform: uppercase;
    padding-top: 1em;
    padding-bottom: 1em;
}
/* =============== RESPONSIVE =========*/

@media (max-width: 575.98px) {
    .BrandImgSection__icons-container{
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
    
    }
        .BrandImgSection__icon-container{
            /* border: 3px solid green; */
            margin: 1em;
        }
        .BrandImgSection__title-container h1{
            font-size: 2.5rem;
            line-height: 3.05rem; 
            padding-top: 1em;
            padding-bottom: 1em;
        }
          
}


@media (min-width: 576px) and (max-width: 767.98px) {
    .BrandImgSection__icons-container{
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
    
    }
        .BrandImgSection__icon-container{
            /* border: 3px solid green; */
            margin: 1em;
        }
        .BrandImgSection__title-container h1{
            font-size: 2.5rem;
            line-height: 3.05rem; 
            padding-top: 1em;
            padding-bottom: 1em;
        }
        
}


@media (min-width: 768px) and (max-width: 991.98px) {
    .BrandImgSection__icons-container{
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
    
    }
        .BrandImgSection__icon-container{
            /* border: 3px solid green; */
            margin: 1em;
        }
        .BrandImgSection__title-container h1{
            font-size: 2.5rem;
            line-height: 3.05rem; 
            padding-top: 1em;
            padding-bottom: 1em;
        }
         
}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .BrandImgSection__icons-container{
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
    
    }
        .BrandImgSection__icon-container{
            /* border: 3px solid green; */
            margin: 1em;
        }
  

}

@media (min-width: 1200px) {
    
.BrandImgSection__icons-container{
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;

}
    .BrandImgSection__icon-container{
        /* border: 3px solid green; */
        margin: 1em;
    }

}