.SubscriptionCard__container{
    /* border: 5px solid red; */
    display: flex;
    max-width: 25rem; 
    padding: 1.875rem 0.9375rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.875rem;
    border-radius: 1.25rem;
    background: #FFF;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
}
    .SubscriptionCard__card-container{
        /* border: 3px solid green; */
        display: flex;
        flex-direction: column;
        align-items: center;
    }
        .SubscriptionCard__card-title{

        }
            .SubscriptionCard__card-title h1{
                color:  #A63F03;
                text-align: center;
                font-family: Montserrat, sans-serif;
                font-size: 4rem;
                font-style: normal;
                font-weight: 700;
                line-height: 1.05rem; 
                text-transform: uppercase;
                margin-top: .5em;
            }
  
            .SubscriptionCard__card-description p{
                color: #000;
                text-align: center;
                font-family: Lato, sans-serif;
                font-size: 2rem;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
            }
            .SubscriptionCard__dolar-price{
                color: #000000;
                text-align: right;
                font-family: Montserrat;
                font-size: 4.5rem;
                font-style: normal;
                font-weight: 700;
                line-height: 1.05rem; 
                text-transform: uppercase;
            }
            .SubscriptionCard__cents-price{
                color: #000000;
                font-family: Montserrat;
                font-size: 2rem;
                font-style: normal;
                font-weight: 700;
                line-height: 1.05rem; 
                text-transform: uppercase;
        
            
            }

            .SubscriptionCard__button-container{
                /* border: 3px solid red; */
            }
                .SubscriptionCard__button{
                    /* border: 3px solid green; */
                }
                .SubscriptionCard__button button{
                    display: flex;
                    padding: 1.25rem 0.3125rem;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 1.25rem; 
                    border-radius: 1.25rem;
                    border: 1px solid #A63F03;
                    color:  #A63F03;
                    text-align: center;
                    font-family: Montserrat, sans-serif;
                    font-size: 1.5rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 1.5rem; /* 100% */
                    text-transform: uppercase;
                }

                .SubscriptionCard__subscription-definition-container{
                    color: #00030A;
                    font-family: Lato, sans-serif;
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 2.25rem; 
                }
                      
  .SubscriptionCard__button button:hover{
    background-color: #A63F03;
    color: white;
  }
  
  /*=========== RESPONSIVE ============*/
  
  @media (max-width: 575.98px) {
    .SubscriptionCard__container{
        padding: 3em;
    }
    .SubscriptionCard__card-title h1{
        color:  #A63F03;
        text-align: center;
        font-family: Montserrat, sans-serif;
        font-size: 2.9rem;
        font-style: normal;
        font-weight: 800;
        line-height: 3.5rem; 
        text-transform: uppercase;
        margin-top: 0;
        margin-bottom: 1em;
     
       
    }
    .SubscriptionCard__card-description p{
        color: #000;
        text-align: center;
        font-family: Lato, sans-serif;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }
    .SubscriptionCard__button-container{
        /* border: 3px solid red; */
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
        .SubscriptionCard__button{
            /* border: 3px solid green; */
            width: 70%;
        }
            .SubscriptionCard__button button{
                border-radius: 1.25rem;
                border: 1px solid #A63F03;
                color:  #A63F03;
                text-align: center;
                font-family: Montserrat, sans-serif;
                font-size: 1.5rem;
                font-style: normal;
                font-weight: 700;
                line-height: 1.5rem; 
                text-transform: uppercase;
                width: 100%;
                background-color: #FFF;
            }
            .SubscriptionCard__subscription-definition-container p {
                margin-top: .8em;
            }
  }
  
  
  @media (min-width: 576px) and (max-width: 767.98px) {
    .SubscriptionCard__container{
        padding: 3em;
    }
    .SubscriptionCard__card-title h1{
        font-size: 2.9rem;
        font-style: normal;
        font-weight: 800;
        line-height: 3.5rem; 
        text-transform: uppercase;
        margin-top: 0;
        margin-bottom: 1em;
    }
    .SubscriptionCard__card-description p{
        color: #000;
        text-align: center;
        font-family: Lato, sans-serif;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
    }
    .SubscriptionCard__button-container{
        /* border: 3px solid red; */
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
        .SubscriptionCard__button{
            /* border: 3px solid green; */
            width: 70%;
        }
            .SubscriptionCard__button button{
                border-radius: 1.25rem;
                border: 1px solid #A63F03;
                color:  #A63F03;
                text-align: center;
                font-family: Montserrat, sans-serif;
                font-size: 1.5rem;
                font-style: normal;
                font-weight: 700;
                line-height: 1.5rem; 
                text-transform: uppercase;
                width: 100%;
                background-color: #FFF;
            }
            .SubscriptionCard__subscription-definition-container p {
                margin-top: .8em;
            }
      
  }
  
  
  @media (min-width: 768px) and (max-width: 991.98px) {
    .SubscriptionCard__container{
        margin-top: 5em;
        /* border: 5px solid red; */
        display: flex;
        max-width: 25rem; 
        padding: 2rem 2rem 2em 2em;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1.875rem;
        border-radius: 1.25rem;
        background: #FFF;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
    }

    .SubscriptionCard__card-title h1{
        color:  #A63F03;
        text-align: center;
        font-family: Montserrat, sans-serif;
        font-size: 2.9rem;
        font-style: normal;
        font-weight: 800;
        line-height: 3.5rem; 
        text-transform: uppercase;
        margin-top: 0;
        margin-bottom: 1em;
    }
    .SubscriptionCard__button-container{
        /* border: 3px solid red; */
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
        .SubscriptionCard__button{
            /* border: 3px solid green; */
            width: 70%;
        }
            .SubscriptionCard__button button{
                border-radius: 1.25rem;
                border: 1px solid #A63F03;
                color:  #A63F03;
                text-align: center;
                font-family: Montserrat, sans-serif;
                font-size: 1.5rem;
                font-style: normal;
                font-weight: 700;
                line-height: 1.5rem; 
                text-transform: uppercase;
                width: 100%;
                background-color: #FFF;
            }
            .SubscriptionCard__subscription-definition-container p {
                margin-top: .8em;
            }




    
  }
  
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .SubscriptionCard__container{
        max-width: 30rem; 
        padding: 0rem 1.8rem 30.5rem 1.8rem ;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 1.25rem;
        background: #FFF;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
        height: 40em !important;
    }

    .SubscriptionCard__card-title h1{
        color:  #A63F03;
        text-align: center;
        font-family: 'Montserrat', sans-serif;
        font-size: 2.9rem;
        font-style: normal;
        font-weight: 800;
        line-height: 3.5rem; 
        text-transform: uppercase;
        margin-top: 0;
        margin-bottom: 1em;
        margin-top: 3.5em;
    }

    .SubscriptionCard__button-container{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
        .SubscriptionCard__button{
            width: 70%;
        }
            .SubscriptionCard__button button{
                border-radius: 1.25rem;
                border: 1px solid #A63F03;
                color:  #A63F03;
                text-align: center;
                font-family: Montserrat, sans-serif;
                font-size: 1.5rem;
                font-style: normal;
                font-weight: 700;
                line-height: 1.5rem; 
                text-transform: uppercase;
                width: 100%;
                background-color: #FFF;
            }
            .SubscriptionCard__subscription-definition-container p {
                margin-top: .8em;
            } 


    
  }
  
  
  @media (min-width: 1200px) {
    .SubscriptionCard__container{
        margin-top: 5em;
        /* border: 5px solid red; */
        display: flex;
        max-width: 25rem; 
        padding: 2rem 2rem 2em 2em;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1.875rem;
        border-radius: 1.25rem;
        background: #FFF;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
        margin-bottom: 1em;
    }
    .SubscriptionCard__card-title h1{
        color:  #A63F03;
        text-align: center;
        font-family: Montserrat, sans-serif;
        font-size: 2.9rem;
        font-style: normal;
        font-weight: 800;
        line-height: 3.5rem; 
        text-transform: uppercase;
        margin-top: 0;
        margin-bottom: 1em;
    }
    .SubscriptionCard__button-container{
        /* border: 3px solid red; */
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
        .SubscriptionCard__button{
            /* border: 3px solid green; */
            width: 70%;
        }
        .SubscriptionCard__button button{
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 800;
            line-height: 1.5rem; 
            text-transform: uppercase;
            width: 100%;
            background-color: #FFF;
        }
        .SubscriptionCard__subscription-definition-container p {
            margin-top: .8em;
        }
  
  }

  @media (min-width: 2000px){
    .SubscriptionCard__container{
        margin-top: 0;
        padding: 2em;
    }
  }