.ContactUs__main-container{
    /* border: 3px solid red; */
}
    .ContactUs__contact-information-container{
        /* border: 3px solid blue ; */
        /* background-color: #ffffff; */
        border-radius: 1.25rem;
        margin-top: 1em;
        margin-bottom: 1em;
    }
        .ContactUs__contact-information{
            /* border: 3px solid green; */
        }

    .ContactUs__contact-form-container{
        /* border: 2px solid purple    ; */
        /* background-color: #edf8eb; */
    }
        .ContactUs__contact-form{
            /* border: 3px solid red; */
            /* background-color: #edf8eb; */
        }





@media (max-width: 575.98px) {
    .ContactUs__main-container{
        /* border: 3px solid green; */
        display: flex;
        flex-direction: column;
        align-items: center;
    }
        .ContactUs__contact-information-container{
            /* border: 3px solid red; */
            width: 100%;
        } 
            .ContactUs__contact-information{
                /* border: 3px solid blue; */
                padding: 2.5em;
            } 
        .ContactUs__contact-form-container{
            /* border: 3px solid purple; */
            width: 100%;
            padding: 1.1em;
        }
            .ContactUs__contact-form{
                /* border: 2px solid yellow; */
                border-radius: 1.25rem;
                padding: 1em 2em 1em 2em;
                /* background-color: #E6E5E5; */
            }
}


@media (min-width: 576px) and (max-width: 767.98px) {
    .ContactUs__main-container{
        /* border: 3px solid green; */
        display: flex;
        flex-direction: column;
        align-items: center;
    }
        .ContactUs__contact-information-container{
            /* border: 3px solid red; */
        } 
            .ContactUs__contact-information{
                /* border: 3px solid blue; */
                padding: 2.5em;
            } 
        .ContactUs__contact-form-container{
            /* border: 3px solid purple; */
            width: 100%;
            padding: 1.1em;
        }
            .ContactUs__contact-form{
                /* border: 2px solid yellow; */
                border-radius: 1.25rem;
                padding: 1em 2em 1em 2em;
                /* background-color: #E6E5E5; */
            }
}


@media (min-width: 768px) and (max-width: 991.98px) {
    .ContactUs__main-container{
        /* border: 3px solid green; */
        display: flex;
        flex-direction: column;
        align-items: center;
    }
        .ContactUs__contact-information-container{
            /* border: 3px solid red; */
        } 
            .ContactUs__contact-information{
                /* border: 3px solid blue; */
                padding: 2.5em;
            } 
        .ContactUs__contact-form-container{
            /* border: 3px solid purple; */
            width: 100%;
            padding: 1.1em;
        }
            .ContactUs__contact-form{
                /* border: 2px solid yellow; */
                border-radius: 1.25rem;
                padding: 1em 2em 1em 2em;
                /* background-color: #E6E5E5; */
            }
}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .ContactUs__main-container{
        /* border: 3px solid green; */
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
        .ContactUs__contact-information-container{
            /* border: 3px solid red; */
            margin-right: 1.5em;
        } 
            .ContactUs__contact-information{
                /* border: 3px solid blue; */
                padding: 2.5em;
            } 
        .ContactUs__contact-form-container{
            /* border: 3px solid purple; */
            width: 100%;
            /* padding: 1.1em; */
            border-radius: 1.25rem;
        }
            .ContactUs__contact-form{
                /* border: 2px solid yellow; */
                border-radius: 1.25rem;
                /* padding: 1em 2em 1em 2em; */
                width: 100%;
                /* background-color: #E6E5E5; */
            }

}

@media (min-width: 1200px) {
    .ContactUs__main-container{
        /* border: 3px solid red; */
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-top: 5em;
        margin-bottom: 10em;
    }
    .ContactUs__contact-information{
        /* border: 3px solid blue; */
        padding: 2.5em;
    } 
        .ContactUs__contact-information-container{
            /* border: 3px solid blue ; */
            width: 40em;
            margin-right: 1.5em;
        }
        .ContactUs__contact-form-container{
            width: 35em;
            /* border: 3px solid green; */
            display: flex;
            flex-direction: column;
            justify-content: center;
            border-radius: 1.25rem;
            padding: 1em 4em 1em 4em;

        }
        .ContactUs__contact-form-container{
            margin-right: 1.5em;
        }

}