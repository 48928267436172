.SubscriptionDescription__main-container{
    /* border: 3px solid red; */
}
               

    .SubscriptionDescription__main-title-container{
        /* border: 3px solid blue */
    }
    .SubscriptionDescription__main-title-container h1{
        color:  #A63F03;
        font-family: Montserrat, sans-serif;
        font-size: 3.75rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-top: .6em;
    }

    .SubscriptionDescription__description-container{
        /* border: 3px solid red; */
        margin-top: 1em;
    }
    .SubscriptionDescription__description-container p{
        color: #00030A;
        font-family: Lato;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.25rem; 
    }

    .SubscriptionDescription__main-description-container{
        margin-top: 3em;
        margin-bottom: 3em;
        /* border: 3px solid green; */
    }
    .SubscriptionDescription__main-description-container p{
        color: #00030A;
        font-family: Lato;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.25rem;
    }



    .SubscriptionDescription__main-icons-container{
        /* border: 6px solid red; */
    }
        .SubscriptionDescription__inner-icons-container{
            /* border: 3px solid green; */
        }
            .SubscriptionDescription__inner-icon-container{
                /* border: 3px solid blue; */
            }
                .SubscriptionDescription__icon-container{
                    /* border: 3px solid yellow; */
                }
                    .SubscriptionDescription__icon{
                        /* border: 3px solid green; */
                    }
                .SubscriptionDescription__description-container{
                    /* border: 3px solid purple; */
                }
                .SubscriptionDescription__description-container p{
                    /* border: 3px solid blue; */
                    color: #00030A;
                    text-align: center;
                    font-family: Montserrat;
                    font-size: 1.5rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-transform: uppercase;

                }



@media (max-width: 575.98px) {
    .SubscriptionDescription__main-icons-container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .SubscriptionDescription__inner-icons-container{
        
        min-width: 80%;
    }
  .SubscriptionDescription__inner-icon-container{
    display: flex;
    flex-direction: row;
    margin-top: 1em;
  }
    .SubscriptionDescription__description-container{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .SubscriptionDescription__icon-container{
        padding-left: .5em;
        padding-right: .5em;
        max-width: 50%;
    }
        .SubscriptionDescription__icon{
            width: 100%;
        }
    .SubscriptionDescription__description-container p{
        font-size: 1rem;
        font-weight: 500;
    }
    .SubscriptionDescription__main-title-container h1{
        font-size: 3rem;

    }

}


@media (min-width: 576px) and (max-width: 767.98px) {
    .SubscriptionDescription__main-icons-container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .SubscriptionDescription__inner-icons-container{
       
        min-width: 80%;
    }
  .SubscriptionDescription__inner-icon-container{
    display: flex;
    flex-direction: row;
    margin-top: 1em;
  }
    .SubscriptionDescription__description-container{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .SubscriptionDescription__description-container p{
        font-size: 1.2rem;
        font-weight: 500;
    }

    .SubscriptionDescription__icon-container{
        padding-left: .5em;
        padding-right: .5em;
        max-width: 50%;
    }
        .SubscriptionDescription__icon{
            width: 100%;
        }
}


@media (min-width: 768px) and (max-width: 991.98px) {
    .SubscriptionDescription__inner-icon-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 30%;
        padding: .5em;
    }
    .SubscriptionDescription__inner-icons-container{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
      
    }
    .SubscriptionDescription__description-container p{
        padding-left: 1em;
        padding-right: 1em;
        font-size: 1rem;
        font-weight: 500;
    }
    .SubscriptionDescription__icon-container{
        padding-left: .5em;
        padding-right: .5em;
        max-width: 90%;
    }
        .SubscriptionDescription__icon{
            width: 100%;
        }
}


@media (min-width: 992px) and (max-width: 1199.98px) {
    .SubscriptionDescription__inner-icon-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 30%;
        padding: .5em;
    }
    .SubscriptionDescription__inner-icons-container{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
      
    }
    .SubscriptionDescription__description-container p{
        padding-left: 1em;
        padding-right: 1em;
        font-size: 1rem;
        font-weight: 500;
    }
    .SubscriptionDescription__icon-container{
        padding-left: .5em;
        padding-right: .5em;
        max-width: 90%;
    }
        .SubscriptionDescription__icon{
            width: 100%;
        }

}

@media (min-width: 1200px) {
    .SubscriptionDescription__inner-icon-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 30%;
        padding: .5em;
    }
    .SubscriptionDescription__inner-icons-container{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
      
    }
    .SubscriptionDescription__main-title-container h1{
        font-size: 3.5rem;
    }

    .SubscriptionDescription__main-description-container p{
        font-size: 1.5rem;
        line-height: 2.9rem;
    }   

    .SubscriptionDescription__description-container p{
        padding-left: 1em;
        padding-right: 1em;
        font-size: 1rem;
        font-weight: 500;
    }
    .SubscriptionDescription__icon-container{
        padding-left: .5em;
        padding-right: .5em;
        max-width: 90%;
    }
        .SubscriptionDescription__icon{
            width: 100%;
        }
}