@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@600&display=swap');

.loading {
  display: flex;
  justify-content: center;
  

}

.LoadingTitle {
    display: flex;
    justify-content: center;
 font-family: 'Oswald', sans-serif;;
    
  
  }

.loader {
    width: 3rem;
    height: 3rem;
    margin: 3rem 0.3rem;
    background: #a63f03;
    border-radius: 50%;
    animation: 0.9s bounce infinite alternate;

    &:nth-child(2) {
      animation-delay: 0.3s;
    }

    &:nth-child(3) {
      animation-delay: 0.6s;
    }
  }

@keyframes bounce {
  to {
    opacity: 0.3;
    transform: translate3d(0, -1rem, 0);
  }
}

.donut {
  width: 2rem;
  height: 2rem;
  margin: 2rem;
  border-radius: 50%;
  border: 0.3rem solid rgba($bluebell, 0.3);
  border-top-color: $bluebell;
  animation: 1.5s spin infinite linear;

  &.multi {
    border-bottom-color: $bluebell;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.ripple {
  width: 2rem;
  height: 2rem;
  margin: 2rem;
  border-radius: 50%;
  border: 0.3rem solid $bluebell;
  transform: translate(50%);
  animation: 1s ripple ease-out infinite;
}

@keyframes ripple {
  from {
    transform: scale(0);
    opacity: 1;
  }

  to {
    transform: scale(1);
    opacity: 0;
  }
}

.multi-ripple {
  width: 2.6rem;
  height: 2.6rem;
  margin: 2rem;

  div {
    position: absolute;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    border: 0.3rem solid $bluebell;
    animation: 1.5s ripple infinite;

    &:nth-child(2) {
      animation-delay: 0.5s;
    }
  }
}

.fancy-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;

  div {
    position: absolute;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;

    &.ring {
      border-width: 0.5rem;
      border-style: solid;
      border-color: transparent;
      animation: 2s fancy infinite alternate;
      
      &:nth-child(1) {
        border-left-color: $bluebell;
        border-right-color: $bluebell;
      }
      &:nth-child(2) {
        border-top-color: $bluebell;
        border-bottom-color: $bluebell;
        animation-delay: 1s;
      }
    }

    &.dot {
      width: 1rem;
      height: 1rem;
      background: $bluebell;
    }
  }
}

@keyframes fancy {
  to {
    transform: rotate(360deg) scale(0.5);
  }
}