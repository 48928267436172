    
.ContactInformation__main-container{
    /* border: 3px solid red; */
}
    .ContactInformation__title-container{
        /* border: 2px solid green; */
        /* width: 30em; */
    }
        .ContactInformation__title{
            color: #A63F03;
            font-family: Montserrat, Flex;
            font-size: 3.75rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

    
    .ContactInformation__description-container{
        /* border: 3px solid blue; */
        /* width: 33em; */
    }
            .ContactInformation__description{
                color: #00030A;
                font-family: Lato;
                font-size: 1.5rem;
                font-style: normal;
                font-weight: 400;
                line-height: 2.25rem; 

                
            }


    .ContactInformation__contact-container{
        /* border: 3px solid green; */
    }


        .ContactInformation__contact-email-container{
            /* border: 3px solid black; */
        }
            .ContactInformation__email-icon-container{
                /* border: 3px solid purple; */
                margin-right: 1em;
            }
                .ContactInformation__email-icon{
                    /* border: 3px solid white; */
                }
            .ContactInformation__email-container{
                /* border: 2px solid purple; */
            }
                .ContactInformation__email{
                    /* border: 3px solid red; */
                    color: #A63F03;
                    text-align: center;
                    font-family: Montserrat, sans-serif;
                    font-size: 2rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 4.375rem; /* 194.444% */
                    letter-spacing: -0.09219rem;
                    margin-bottom: 0;
                }



        .ContactInformation__contact-number-container{
            /* border: 3px solid blue; */
        }
            .ContactInformation__person-icon-container{
                /* border: 3px solid red; */
                margin-right: 1em;
            }
                .ContactInformation__person-icon{
                    /* border: 2px solid green; */
                }

            .ContactInformation__number-container{
                /* border: 3px solid black; */
            }
                .ContactInformation__number{
                    /* border: 2px solid green; */
                    color: #A63F03;
                    font-family: Montserrat;
                    font-size: 2rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 4.375rem; /* 194.444% */
                    letter-spacing: -0.09219rem;
                    margin-bottom: 0;
                }


    .ContactInformation__business-days-container{
        /* border: 2px solid green; */
    }
        .ContactInformation__business-days{
            /* border: 3px solid purple; */
        }



@media (max-width: 422px) {
.ContactInformation__contact-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}
    .ContactInformation__contact-email-container{
    
        flex-direction: column !important;
        align-items: center !important;
        
    }

    .ContactInformation__contact-number-container{
        /* border: 3px solid red; */
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
    }
}


@media (max-width: 575.98px) {
    .ContactInformation__title{
        font-size: 1.5rem;
    }
    .ContactInformation__description{
        font-size: 1.2rem;
        line-height: 2rem; 
    }

  .ContactInformation__main-container{
    /* border: 20px solid blue; */
  }
    .ContactInformation__title-container{
        /* border: 3px solid purple; */
        
    }  
        .ContactInformation__title{
            /* border : 3px solid red; */
            font-size: 2.5rem;
        }
  .ContactInformation__contact-container{
    /* border: 6px solid yellow; */
  }
    .ContactInformation__contact-email-container{
        /* border: 2px solid red; */
        display: flex;
        flex-direction: row;
    }
        .ContactInformation__email-icon-container{
            /* border: 3px solid purple; */
            width: 3em;
            min-width: 3em;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
            .ContactInformation__email-icon{
                /* border: 3px solid blue; */
                width: 100%;
            }
        .ContactInformation__email-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
            .ContactInformation__email{
                font-size: 1.1rem;
                margin-bottom: 0;
                word-wrap: break-word;
            }


    .ContactInformation__contact-number-container{
        /* border: 2px solid red; */
        display: flex;
        flex-direction: row;    
    }
        .ContactInformation__person-icon-container{
                width: 3em;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
        }
            .ContactInformation__person-icon{
                width: 100%;
            }
        .ContactInformation__number-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
            .ContactInformation__number{
                font-size: 1.1rem;
                margin-bottom: 0;
            }
}


@media (min-width: 576px) and (max-width: 767.98px) {
    .ContactInformation__title{
        font-size: 1.5rem;
    }
    .ContactInformation__description{
        font-size: 1.2rem;
        line-height: 2rem; 
    }

  .ContactInformation__main-container{
    /* border: 20px solid blue; */
  }
    .ContactInformation__title-container{
        /* border: 3px solid purple; */
        
    }  
        .ContactInformation__title{
            /* border : 3px solid red; */
            font-size: 2.8rem;
        }
  .ContactInformation__contact-container{
    /* border: 6px solid yellow; */
  }
    .ContactInformation__contact-email-container{
        /* border: 2px solid red; */
        display: flex;
        flex-direction: row;
    }
        .ContactInformation__email-icon-container{
            /* border: 3px solid purple; */
            width: 3em;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
            .ContactInformation__email-icon{
                /* border: 3px solid blue; */
                width: 100%;
            }
        .ContactInformation__email-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
            .ContactInformation__email{
                font-size: 1.1rem;
                margin-bottom: 0;
            }


    .ContactInformation__contact-number-container{
        /* border: 2px solid red; */
        display: flex;
        flex-direction: row;    
    }
        .ContactInformation__person-icon-container{
                width: 3em;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
        }
            .ContactInformation__person-icon{
                width: 100%;
            }
        .ContactInformation__number-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
            .ContactInformation__number{
                font-size: 1.1rem;
                margin-bottom: 0;
            }
}


@media (min-width: 768px) and (max-width: 991.98px) {
        .ContactInformation__title{
        font-size: 1.5rem;
    }
    .ContactInformation__description{
        font-size: 1.2rem;
        line-height: 2rem; 
    }

  .ContactInformation__main-container{
    /* border: 20px solid blue; */
  }
    .ContactInformation__title-container{
        /* border: 3px solid purple;
         */
    }  
        .ContactInformation__title{
            /* border : 3px solid red; */
            font-size: 3rem;
        }
  .ContactInformation__contact-container{
    /* border: 6px solid yellow; */
  }
    .ContactInformation__contact-email-container{
        /* border: 2px solid red; */
        display: flex;
        flex-direction: row;
    }
        .ContactInformation__email-icon-container{
            /* border: 3px solid purple; */
            width: 3em;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
            .ContactInformation__email-icon{
                /* border: 3px solid blue; */
                width: 100%;
            }
        .ContactInformation__email-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
            .ContactInformation__email{
                font-size: 1.1rem;
                margin-bottom: 0;
            }


    .ContactInformation__contact-number-container{
        /* border: 2px solid red; */
        display: flex;
        flex-direction: row;    
    }
        .ContactInformation__person-icon-container{
                width: 3em;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
        }
            .ContactInformation__person-icon{
                width: 100%;
            }
        .ContactInformation__number-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
            .ContactInformation__number{
                font-size: 1.1rem;
                margin-bottom: 0;
            }
}


@media (min-width: 992px) and (max-width: 1199.98px) {

        .ContactInformation__title{
        font-size: 1.5rem;
    }
    .ContactInformation__description{
        font-size: 1.2rem;
        line-height: 2rem; 
    }

  .ContactInformation__main-container{
    /* border: 20px solid blue; */
  }
    .ContactInformation__title-container{
        /* border: 3px solid purple;  */
        
    }  
        .ContactInformation__title{
            /* border : 3px solid red; */
            font-size: 3rem;
        }
  .ContactInformation__contact-container{
    /* border: 6px solid yellow; */
  }
    .ContactInformation__contact-email-container{
        /* border: 2px solid red; */
        display: flex;
        flex-direction: row;
    }
        .ContactInformation__email-icon-container{
            /* border: 3px solid purple; */
            width: 3em;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
            .ContactInformation__email-icon{
                /* border: 3px solid blue; */
                width: 100%;
            }
        .ContactInformation__email-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
            .ContactInformation__email{
                font-size: 1.1rem;
                margin-bottom: 0;
            }


    .ContactInformation__contact-number-container{
        /* border: 2px solid red; */
        display: flex;
        flex-direction: row;    
    }
        .ContactInformation__person-icon-container{
                width: 3em;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
        }
            .ContactInformation__person-icon{
                width: 100%;
            }
        .ContactInformation__number-container{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
            .ContactInformation__number{
                font-size: 1.1rem;
                margin-bottom: 0;
            }

}

@media (min-width: 1200px) {
    
.ContactInformation__main-container{
    /* border: 3px solid rgb(0, 0, 0); */
    
}
    .ContactInformation__title-container{
        /* border: 2px solid green;
        width: 30em; */
        
    }
        .ContactInformation__title{
            /* border: 2px solid blue; */
           
            font-size: 3.9rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            /* margin-top: 1.5em; */
    
        }

    
    .ContactInformation__description-container{
        /* border: 3px solid blue; */
        width: 33em;
    }
        .ContactInformation__description{
            color: #00030A;
            font-family: Lato;
            font-size: 1.3rem;
            font-style: normal;
            font-weight: 400;
            line-height: 2.25rem; 
            margin-top: 1.5em;
        }


    .ContactInformation__contact-container{
        /* border: 3px solid green; */
    }


        .ContactInformation__contact-email-container{
            /* border: 3px solid black; */
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
        }
            .ContactInformation__email-icon-container{
                /* border: 3px solid purple; */
                width: 3em;
            }
                .ContactInformation__email-icon{
                    /* border: 3px solid white; */
                    width: 100%;
                }
            .ContactInformation__email-container{
                /* border: 2px solid purple; */
            }
                .ContactInformation__email{
                    /* border: 3px solid red; */
                    text-align: center;
                    font-family: Montserrat;
                    font-size: 1.5rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 4.375rem; 
                    letter-spacing: -0.09219rem;
                    margin-bottom: 0;
                }



        .ContactInformation__contact-number-container{
            /* border: 3px solid blue; */
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
        }
            .ContactInformation__person-icon-container{
                /* border: 3px solid red; */
                width: 3em;
            }
                .ContactInformation__person-icon{
                    /* border: 2px solid green; */
                    width: 100%;
                }

            .ContactInformation__number-container{
                /* border: 3px solid black; */
            }
                .ContactInformation__number{
                    /* border: 2px solid green; */
                    font-family: Montserrat;
                    font-size: 1.5rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 4.375rem; /* 194.444% */
                    letter-spacing: -0.09219rem;
                    margin-bottom: 0;
                }


    .ContactInformation__business-days-container{
        /* border: 2px solid green; */
        display: flex;
        width: 33.5rem;
        height: 6.4375rem;
        flex-direction: column;
        justify-content: center;
    }
        .ContactInformation__business-days{
            /* border: 3px solid purple; */
            color: #00030A;
            font-family: Lato;
            font-size: 1.2rem;
            font-style: normal;
            font-weight: 400;
            line-height: 2rem; 
        }

}